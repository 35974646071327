:root {
  touch-action: pan-x pan-y;
  height: 100%;
  --primary-color: #45bbc6;
  --bg-dark: rgba(0, 0, 0, 0.75);
  --but-color: #333;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f6fb;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
.button {
  padding: 8px 12px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 3px;
  background: white;
  transition: all 0.3s ease-in-out;
}

button:hover,
.button:hover {
  background: black;
  color: white;
}

button:active,
.button:active {
  background: black;
  color: white;
}
