.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}
