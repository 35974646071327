.tooltip {
  border-radius: 5px;
  padding: 3px 10px;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  margin: 0;
  backdrop-filter: blur(20px);
  color: white;
}
