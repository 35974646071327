.feedback-button {
  margin-top: 25px;
  display: block;
  cursor: pointer;
  text-decoration: underline;
}

.feedback-form {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  max-width: 400px;
  margin: 10px auto;
}

textarea {
  min-height: 80px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
}

.feedback-send-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 4px 0;
  align-self: flex-end;
  background: none;
  box-shadow: none;
}

.feedback-send-button:hover {
  background: white;
  color: black;
  border-radius: 4px;
}
