.graph-wrapper {
  display: flex;
  height: 260px;
  align-content: stretch;
  justify-content: center;
}
.bar-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  max-width: 100px;
  border-radius: 6px;
}

.outer-bar {
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  flex-grow: 1;
  margin: 10px;
}

.bar-container:hover {
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.bar-container:hover > .outer-bar > .count-text {
  color: rgba(255, 255, 255, 0.6);
}

.inner-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary-color);
  border-radius: 6px;
}

.count-text {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  justify-content: center;
  color: rgba(255, 255, 255, 0);
  font-size: 34px;
  font-weight: bold;
}

.bar-container .image-wrapper {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 14px;
  width: 50px;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-self: center;
}

.bar-container div img {
  max-width: 40px;
  margin: 10px;
}
