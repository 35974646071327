.fab {
  width: 3rem;
  height: 3rem !important;
  border-radius: 50%;
  position: absolute;
  right: 5%;
  top: -40%;
  background: white;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem !important;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.link {
  cursor: pointer;
}
