.timer-wrapper {
  background: var(--bg-dark);
  color: white;
  padding: 10px 25px;
  display: flex;
  flex-flow: column;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}

/* bar */

.timer-bar-wrapper {
  height: 10px;
  position: relative;
  background: var(--primary-color);
  margin: 8px 0 4px 0;
}

.timer-bar-fill {
  background: rgba(50, 50, 50, 0.72);
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
}

/* Count */

.counting-wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-width: 85px;
}

.count-time {
  color: var(--primary-color);
}

.countContainertitl {
  display: inline-flex;
  justify-content: space-between;
}

.countContainerLoc {
  margin: 0px;
  text-align: end;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.countContainerLoc img {
  height: 12px;
  padding-right: 10px;
}

.countContainerCount {
  margin: 0px;
}

.badge-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.badge {
  border-radius: 50%;
  margin: 3px;
  height: 18px;
  width: 18px;
  background: var(--primary-color);
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  line-height: 17px;
  transform-origin: center center;
  transition: transform 0.5s ease-out;
  animation: pulseBig 1s ease-in-out 1;
}

.badge:last-child {
  margin-right: 1px;
  animation: pulse 2s ease-in-out infinite;
}

.badge:nth-child(3) {
  background: #e5bd96;
  transform: scale(1.3);
  animation: pulseBig 1s ease-in-out 2;
}

.badge:nth-child(6) {
  background: silver;
  transform: scale(1.3);
  animation: pulseBig 1s ease-in-out 2;
}

.badge:nth-child(12) {
  background: goldenrod;
  transform: scale(1.3);
  animation: pulseBig 1s ease-in-out 3;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseBig {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
