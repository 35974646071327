.App {
  text-align: center;
  /* min-height: 100vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Header */

.headerstyle {
  z-index: 1;
  padding: 5px;
  align-items: flex-end;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.lcityflows {
  height: 50px;
  padding: 5px;
}

.lcityflows img {
  height: inherit;
}
.lcityflows:hover,
.lcityflows:active {
  background: #282c34;
  filter: invert(100%);
}
.headertitle {
  display: inline-flex;
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding: 5px;
  color: #333;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

/* Footer */

.lhomebut {
  height: 25px;
  padding: 5px;
}

.footerstyle {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 6px 10px;
  align-items: flex-end;
  height: 20px;
  /* justify-content: space-between; */
  font-size: 9px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.footerstyle img {
  height: inherit;
  margin-right: 10px;
}

.footerstyle p {
  align-self: center;
  justify-self: flex-end;
  text-align: right;
  min-width: 150px;
  color: #696868;
  margin: 0 0 0 10px;
  flex-grow: 1;
}

/* Welcome */
.CFBackGroundImg {
  background-image: url(./_img/otherimages/Visual_CityFlows.jpg);
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.5);
}

.CFWelcometx,
.CFChooseCity {
  margin-top: 10px;
  padding: 20px;
  position: relative;
  color: white;
  font-size: 18px;
  margin-bottom: 30px;
}

.CFWelcometx h1 {
  text-transform: uppercase;
}

.CFWelcometx a {
  color: inherit;
}

.CFWelcometx strong.city-name {
  text-transform: capitalize;
}

.CFListInstructions {
  padding: 10px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CFListInstructions div {
  border-bottom: 2px solid white;
  display: inline-flex;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}

.CFListInstructions .alright {
  text-align: right;
  margin-left: 20px;
}

.CFListInstructions .alleft {
  text-align: left;
  margin-right: 20px;
}

.CFListInstructions div h1 {
  font-size: 45px;
  margin: 0;
  font-weight: 400;
  padding-right: 10px;
}

.CFListInstructions div p {
  margin: 0;
}

/* Street picker */
.selectedSegmentStyle {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  padding: 5px;
  z-index: 1;
  padding: 5px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: rgba(33, 33, 33, 0.9);
  color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
}

.selectedSegmentlocIconWrapper {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.selectedSegmentlocIconWrapper img {
  height: 22px;
}

.selectedSegmentlocIconWrapper h2 {
  margin: 6px;
  word-break: break-all;
}

.selectedSegmentTitle {
  margin-bottom: 10px;
  text-align: center;
  margin-left: 5px;
}

.selectedSegmentTitle h2 {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.selectedSegmentTitle div {
  font-size: 15px;
  color: rgb(165, 165, 165);
}
.selectedSegmentStyle button {
  height: 40px;
  z-index: 2;
  margin-bottom: 2rem;
}

.selectedSegmentStyle p {
  margin: 0;
}

.modContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 36px;
  font-weight: 400;
  border: 2px solid var(--but-color);
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 3px 10px 0 rgba(14, 10, 10, 0.15);
}
.modContainer button img {
  display: block;
  height: 36px;
  width: 36px;
}
.modContainer button:active,
.modContainer button:hover {
  border: var(--but-color);
}

.modContainer button img {
  width: 100%;
}

.modContainer {
  margin: 6px 14px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  background: white;
  display: flex;
  max-width: 350px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
}

.modCenter {
  display: flex;
  align-items: center;
}

.modLogo {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 14px;
  width: 80px;
}

.modLogo div {
  color: #696868;
}

.modLogo img {
  margin: 4px;
  width: 32px;
}

.ModScoretxt {
  font-size: 32px;
  width: 50px;
}

/** GENERIC
**/
button,
.button {
  border: none;
  border-radius: 0px;
  background-color: rgb(246 246 246);
  color: var(--but-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

button:active,
button:hover,
.button:active,
.button:hover {
  background: var(--primary-color);
  color: white;
  cursor: pointer;
}
button:disabled,
button:disabled:hover,
.button:disabled,
.button:disabled:hover {
  border: 1px solid lightgray;
  color: gray;
  background: transparent;
  cursor: not-allowed;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.countContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px; /* place for footer */
}

.countFooter {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  margin-bottom: 2rem;
}

.countFooter img {
  fill: var(--primary-color);
  margin-left: 10px;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.question-mark-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0;
  padding-left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--but-color);
  color: white;
  box-shadow: none;
  font-size: 14px;
}

@import "modal.css";
