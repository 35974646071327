.tabselection-container {
  display: flex;
  margin: 16px 10px;
  justify-content: center;
}

.tabselection-container > button {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  flex-grow: 1;
}

.tabselection-container > button:first-child {
  border-radius: 6px 0 0 6px;
  border-right: none;
}
.tabselection-container > button:last-child {
  border-radius: 0 6px 6px 0;
  border-left: none;
}
