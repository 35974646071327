/** MODAL **/
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 80px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: var(--primary-color);
  margin: auto;
  padding: 0;
  width: 80%;
  max-width: 410px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  color: white;
  border-radius: 10px;
}

.failure .modal-content {
  background-color: var(--but-color);
}

.info .modal-content {
  background-color: var(--but-color);
}

.modal-content h2 {
  margin: 10px;
}

.modal-content p {
  margin: 10px;
}

.modal-content button {
  margin: 10px;
}

.modal-header {
  padding: 2px 16px;
  min-height: 40px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 15px;
  cursor: pointer;
}

.close:hover,
.close:active,
.close:focus {
  color: #3f98bd;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  padding-top: 2px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modal img {
  width: 64px;
  height: 64px;
  margin: auto;
  margin-right: 10px;
  padding: 10px 15px;
  color: white;
  background: whitesmoke;
  border-radius: 10px;
}

.modal-content button {
  background-color: transparent;
  color: white;
  border: solid white 1px;
}

.modal-content table {
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 5px;
  text-align: left;
  align-self: center;
  border-collapse: collapse;
}

.modal-content table tr:not(:first-of-type) {
  border-top: 1px solid white;
}
.modal-content table td {
  padding: 10px 5px;
}
